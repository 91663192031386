import { useMemo, useState } from 'react'
import {
  CouponCarouselAlvi,
  CouponCarouselItem
} from '@smu-chile/pkg-unimarc-components/stories/organisms/CouponCarouselAlvi/CouponCarouselAlvi'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { getBorderStyle } from '@smu-chile/pkg-unimarc-components/helpers/getBorderStyle'
import { useCouponsAlvi } from '@smu-chile/pkg-unimarc-hooks/hooks/useCouponsAlvi'
import { CouponModal } from 'components/CouponModal'
import { useCouponsCarousel } from './helpers/useCouponCarousel'
import { Container } from '@smu-chile/pkg-unimarc-components'

export const couponCarouselColors = {
  border: getBorderStyle('--border-width-xs', 'solid', '--color-neutral-black'),
  borderRadius: getGlobalStyle('--border-radius-full')
}

export const CouponCarousel = () => {
  const [couponId, setCouponId] = useState(null)

  const coupons = useCouponsAlvi()
  const carouselCoupons = useCouponsCarousel({ coupons: coupons.coupons })

  const carouselCoupon = useMemo(() => {
    return coupons.search(couponId)
  }, [coupons.coupons, couponId])

  const handleCouponActivateClick = (coupon?: CouponCarouselItem) => {
    coupons.activate(coupon.id)
  }

  const handleCouponDetailClose = () => {
    setCouponId(null)
  }

  const handleCouponModalActivateClick = () => {
    coupons.activate(couponId)
  }

  const handleCouponViewProductsClick = (coupon?: CouponCarouselItem) => {
    setCouponId(coupon.id)
  }

  return (
    <Container>
      <CouponCarouselAlvi
        coupons={carouselCoupons}
        isLoading={coupons.isLoading}
        onCouponActivateClick={handleCouponActivateClick}
        onCouponViewProductsClick={handleCouponViewProductsClick}
      />

      {carouselCoupon && (
        <CouponModal
          coupon={carouselCoupon}
          onClose={handleCouponDetailClose}
          onCouponActivateClick={handleCouponModalActivateClick}
        />
      )}
    </Container>
  )
}
